@import "src/sass/main.scss";

.NotebookBanner-container {
	display: flex;
	flex-direction: column;
}

.NotebookBanner-title {
	color: $primary-font-color-black;
}

.NotebookBanner-tags {
	display: flex;
	flex-wrap: wrap;
	color: $primary-color-dark-1;
	column-gap: $padding;
	width: 100%;

	.NotebookBanner-tags-right-side {
		margin-left: auto;
	}
}