@import "src/sass/main.scss";

.DashboardPage-container {
	background-color: $tertiary-color-1;
	flex: 1;
}
.DashboardPage-body {
	flex: 1;
	overflow: auto;
}
.DashboardPage-footer {
	padding: 5px;
	.DorianeButton {
		display: inline;
		margin-left: 20px;
	}
}