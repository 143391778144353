@import "src/sass/main.scss";

.ECWTemplateObsList-body {
	padding: $padding;
	gap: $padding-small;
}

.ECWTemplateObsList-card-list {
	display: flex;
	flex-wrap: wrap;
	gap: $padding;
	overflow-y: auto;
}
