@import "src/sass/main.scss";

.LabelledCard-container {
	height: fit-content;
	margin-top: -10px;
}

.LabelledCardMultiline {
	min-height: 92px;
	padding: $padding-small;
}

.LabelledCard {
	padding: $padding-small;
	min-height: $element-min-height;
}

.LabelledCard-label {
	font-size: $font-size-small;
	padding-left: $padding;
	margin-bottom: -5px;
	color: $black;
}

.LabelledCardMultiline-value {
	font-size: $font-size;
	font-weight: bold;
	color: $black;
	padding-top: 3px;
	min-height: $element-min-height;
	overflow: hidden;
	text-overflow: ellipsis;
}

.LabelledCard-value {
	font-size: $font-size;
	font-weight: bold;
	color: $black;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}