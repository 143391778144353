@import "src/sass/main.scss";

.ObsRoundEditionPage-container {
	display: flex;
	position: relative;
}

.ObsRoundEditionPage-edit-part {
	width: 900px;
	height: 100%;
	background-color: $primary-background-color;
}

.ObsRoundEditionPage-header-banner {
	background-color: $secondary-background-color;
}

.ObsRoundEditionPage-btn-send.MuiButtonBase-root{
	width: 200px !important; // necessary for overide style props
}