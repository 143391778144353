@import "src/sass/main.scss";

.ECW-right-side {
	display: flex;
	flex: 1;
	min-width: 400px;
}

.ECW-body-container {
	display: flex;
	width: 40%;
	min-width: 400px;
	height: 100%;
	flex-direction: column;
	background-color: $white;
}

.ECW-body {
	padding: $padding;
	gap: $padding-small;
	overflow-y: auto;
}

.ECW-title-container {
	display: flex;
	justify-content: space-between;
}
