@import "src/sass/main.scss";

.DataStatusMenu-item {
  padding: $padding-small;
  cursor: pointer;
  display: flex;
  gap: $padding-small;

  &:hover {
    background-color: $grey;
  }
}
.DataStatusMenu-item-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}
