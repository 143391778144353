@import "src/sass/main.scss";

.DorianeRadioButton.MuiRadio-root {
	color: lightgray;
}

.DorianeRadioButton.MuiRadio-root.Mui-checked {
	color: $secondary-color;
}

.DorianeRadioButton * {
	transition: none;
}
