@import "src/sass/main.scss";

.Stepper-container {
	width: 100%;
	padding-bottom: $padding;
	--step-widht: 35px;
	--step-height: 35px;
}

.StepperLevel-container {
	width: var(--step-widht);
	height: var(--step-widht);
	position: relative;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	* {
		transition: none;
	}

	&.StepperLevel-disabled {
		cursor: default;
	}
}

.StepperLevel-body {
	width: 100%;
	height: 100%;
	background-color: $primary-background-color;
	color: $primary-font-color;

	text-align: center;
	font-weight: bold;
	border-radius: 5px;

	&.StepperLevel-completed {
		background-color: $primary-color-light-1;
		color: $secondary-font-color;
	}

	&.StepperLevel-current {
		background-color: $secondary-color;
		color: $secondary-font-color;
	}

	&.StepperLevel-disabled {
		color: $grey;
	}
}

.StepperLevel-title {
	position: absolute;
	left: calc(var(--step-widht) / 2); //center the text
	translate: -50%;
	color: $secondary-font-color;
	width: max-content;

	&.StepperLevel-completed {
		color: $primary-color-light-1;
	}

	&.StepperLevel-current {
		color: $secondary-color;
	}
}

.StepperLink {
	background-color: $primary-background-color;
	height: 5px;
	flex: 1;

	&.StepperLevel-current,
	&.StepperLevel-completed {
		background-color: $primary-color-light-1;
	}
}

.StepperSpace {
	height: 5px;
	flex: 1;
}

.StepperLevel-error {
	position: absolute;
	top: -10px;
	right: -10px;
	background-color: $error-color;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	text-align: center;
	justify-content: center;
}
