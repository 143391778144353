@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.colorDot {
	width: $x3l;
	height: $x3l;
	border-radius: $lg;
}
.colorDotContainer {
	padding: $borderStrokeBig;
	box-sizing: border-box;
	border-radius: $lg;
	border: solid $borderStroke transparent;
}
.colorDotContainer.colorDotContainerSelected {
	border-color: $active60;
	border-radius: $xl;
}
