@import "src/sass/main.scss";

.TaskTemplateModal-left {
	width: 55%;
	border-right: $border-style;
}

.TaskTemplateModal-right {
	width: 45%;
}

.TaskTemplateModal-search {
	border-bottom: $border-style;
}

.TaskTemplateModal-box {
	background-color: #dbf5ff;
	border-radius: $radius-large;
	align-items: center;
}
