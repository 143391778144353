@import "src/sass/main.scss";

.DorianeDatePicker > .MuiInputBase-root {
	background-color: $grey-transparent;
	border-color: $grey-dark;
}

.DorianeDatePicker > .MuiInputBase-root fieldset {
	border: $border $grey;
	border-radius: $radius;
}

.DorianeDatePicker > .MuiInputBase-root:hover fieldset {
	border-color: $secondary-color !important;
}

.DorianeDatePicker > .MuiInputBase-root.Mui-focused fieldset {
	border-color: $secondary-color !important;
}

.DorianeDatePicker.MuiTextField-root label {
	line-height: 14px !important;
	color: $black !important;
}

.DorianeDatePicker.MuiTextField-root input {
	height: $element-min-height !important;
	max-height: $element-min-height !important;
	padding: $padding-micro $padding !important;
}

.DorianeDatePicker-disabled > .MuiInputBase-root {
	background-color: $secondary-background-color;
}

.DorianeDatePicker-disabled > .MuiInputBase-root fieldset {
	border: none;
}

.DorianeDatePicker-disabled.MuiTextField-root input {
	height: $element-min-height !important;
	max-height: $element-min-height !important;
	padding: 0 $padding !important;
}

.DorianeDatePicker-disabled.MuiTextField-root label {
	margin-top: -5px !important;
}
