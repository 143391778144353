@import "src/sass/main.scss";

.SearchableCombobox.MuiAutocomplete-root {
  background-color: $white;
  border-radius: $radius;
}

.SearchableCombobox.MuiAutocomplete-root fieldset {
  border: $border $grey;
  border-radius: $radius;
}

.SearchableCombobox .MuiInputBase-root.Mui-focused fieldset {
  border-color: $secondary-color !important;
}

.SearchableCombobox .MuiInputBase-root:hover fieldset {
  border-color: $secondary-color !important;
}

.SearchableCombobox .MuiFormControl-root label {
  line-height: 16px !important;
}
.SearchableCombobox .MuiInputBase-root {
  height: $element-min-height;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
// position of the label
.SearchableCombobox .MuiInputLabel-outlined {
  // close
  transform: translate(12px, 7px) scale(1) !important;
  // open
  &.MuiInputLabel-shrink {
    transform: translate(12px, -8px) scale(0.75) !important;
  }
}

.SearchableCombobox-popper.MuiAutocomplete-popper {
  transition: none !important;
}

.SearchableCombobox input.MuiInputBase-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: 100%;
  height: 100%;
}
