@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";

.TemplateObsVariablesPart-variable-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: $padding-small;
}

.TemplateObsVariablesPart-linked-var {
	flex-shrink: 0;
}

.TemplateObsVariablesPart-variable-container {
	max-width: $doubleInputWidth;
	gap: $md;
	margin: $xl auto;
	width: 100%;
}
