@import "src/sass/main.scss";

.OperationBanner-container {
	row-gap: $padding-micro;
}

.OperationBanner-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.OperationBanner-title {
	color: $primary-font-color-black;
	-webkit-line-clamp: 1;
}

.OperationBanner-tags {
	display: flex;
	flex-wrap: wrap;
	color: $primary-color-dark-1;
	column-gap: $padding;
	row-gap: $padding-small;
}

.OperationBanner-description {
	color: $black;
	font-weight: bold;
	-webkit-line-clamp: 3;
}
