@import "src/sass/main.scss";

.ImgBannerCard-container {
    background-color: $secondary-background-color;
    border-radius: $radius;
    display: flex;
    padding: $padding-micro;
    word-break: break-word;
}

.ImgBannerCard-body {
    padding: $padding-small;
    // padding-left: $padding; // Fix honteux pour régler une card dépassant à cause de quelques pixels...
    flex: 1;
    min-width: 0;
}

.ImgBannerCard-side-banner-image-layer {
    background-color: $image-layer;
    display: flex;
    justify-content: center;
}

.ImgBannerCard-side-banner-image {
    border-radius: $radius;
    overflow: hidden;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    width: 60px;
}

.ImgBannerCard-side-banner-logo {
    width: 40px;
    height: 40px;
    margin: $padding-small;
    border-radius: 100%;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.28));
}