@import "src/sass/main.scss";

.PictureFilterDrawer {
	gap: $padding-large;
	padding: $padding-small;
}

.PictureFilterDrawer-top {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}

.PictureFilterDrawer-list {
	display: flex;
	flex-direction: column;
	gap:$padding;
	padding:$padding;
}

.PictureFilterDrawer-block {
	padding-bottom: $padding;
	gap: $padding;
}
.PictureFilterDrawer-double-input {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: $padding-small;
}

.PictureFilterDrawer-image-layer {
	border-radius: $radius;
	display: flex;
	justify-content: center;
	width: 25px;
	height: 25px;
	background-color: #ffffff;

	padding: 2px $padding-micro;
	text-align: center;
}

.PictureFilterDrawer-image-icon {
	height: 20px;
	width: 20px;
}

.PictureFilterDrawer-list .Combobox .MuiOutlinedInput-input{
	max-height: none !important;
}