@import "src/sass/main.scss";

.TopMenu-container {
	border-bottom: $border-style;
	padding: $padding-small $padding-small;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: $padding-small;
}

.TopMenu-right-side {
	display: flex;
	gap: $padding-small;
}

.TopMenu-left-side {
	margin-right: auto;
	display: flex;
	gap: $padding-small;
	align-items: center;
}

.TopMenu-title {
	color: $primary-font-color-black;
	-webkit-line-clamp: 1;
}