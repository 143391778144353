@import "src/sass/main.scss";

.OperationForm-container {
	padding: $padding;
}

.OperationForm-title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.OperationForm-user-list {
	gap: $padding-small;
}
