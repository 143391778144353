$black: #255048;
$white: #fff;
$grey: #efefef;
$grey-dark: #c1cfc8;
$white-transparent: #ffffff60;
$black-transparent: #255048d4;
$grey-transparent: #efefef94;

$primary-background-color: $white;
$secondary-background-color: #dcebe4;
$tertiary-background-color: #bae2f3;

$shadow: 2px 5px 7px -6px #255048;

$image-layer: #80dacabb;
$image-layer-template: #9dcdedcf;

$primary-font-color: $black;
$primary-font-color-black: black;

$secondary-font-color: $white;
$tertiary-font-color: #2a4d46;
$warn-font-color: #ffa726;
$err-font-color: #f44336;
$success-font-color: #66bb6a;
$info-font-color: #29b6f6;

$padding-nano: 3px;
$padding-micro: 5px;
$padding-small: 7px;
$padding-medium: 10px;
$padding: 15px;
$padding-large: 22px;

$font-size: 14px;
$font-size-large: 17px;
$font-size-extra-large: 20px;
$font-size-small: 11px;

$primary-color-dark-1: #0d8772;
$primary-color-light-1: #99cc33;
$primary-color-light-2: #22b199;

$secondary-color: #00b0f0;
$secondary-color-transparent: #00b0f07a;
$secondary-color-light: #e5f7fd;

$error-color: #ff4343;

$tertiary-color-1: #f5f5f5;
$tertiary-color-2: $white;
$tertiary-color-3: #f2f2f2;

$border: 2px solid;
$thin-border: 1px solid;
$border-style: $border $grey;
$border-selection-style: $border $secondary-color;
$thin-border-style: $thin-border $grey-dark;
$thin-border-secondary-style: $thin-border $secondary-background-color;

$radius-large: 10px;
$radius: 4px;
$radius-small: 2px;
$element-min-height: 32px;

$system-var-color: #0f0d87;
$standard-var-color: #0d8772;
$custom-var-color: #ffbd13;

$exp-validated-color: #50c878;
$exp-not-validated-color: #dc143c;

$notebook-reviewed-color: #00b0f0;

$snackbar-max-content-size: 400px;

$completion-partial-color: #ffdd89;
$completion-completed-color: #cbe598;

$draft-color: #ffbd13;
$ready-color: #0d8772;
$in-progress-color: #d1c12e;
$completed-color: #99cc33;
$disabled-color: #888;

:export {
	black: $black;
	black-transparent: $black-transparent;
	white: $white;
	white-transparent: $white-transparent;
	grey-transparent: $grey-transparent;
	grey: $grey;
	grey-dark: $grey-dark;

	primary-color-dark-1: $primary-color-dark-1;
	primary-font-color-black: $primary-font-color-black;
	primary-color-light-1: $primary-color-light-1;
	primary-color-light-2: $primary-color-light-2;

	shadow: $shadow;

	error-color: $error-color;
	secondary-color: $secondary-color;
	secondary-color-transparent: $secondary-color-transparent;

	tertiary-color-1: $tertiary-color-1;
	tertiary-color-2: $tertiary-color-2;
	tertiary-color-3: $tertiary-color-3;
	image-layer: $image-layer;
	image-layer-template: $image-layer-template;

	padding: $padding;
	padding-large: $padding-large;
	padding-small: $padding-small;
	padding-micro: $padding-micro;
	padding-nano: $padding-nano;

	primary-background-color: $primary-background-color;
	secondary-background-color: $secondary-background-color;

	primary-font-color: $primary-font-color;
	secondary-font-color: $secondary-font-color;
	warn-font-color: $warn-font-color;
	err-font-color: $err-font-color;

	font-size: $font-size;
	font-size-large: $font-size-large;
	font-size-small: $font-size-small;
	font-size-extra-large: $font-size-extra-large;

	element-min-height: $element-min-height;

	border-style: $border-style;
	thin-border: $thin-border;
	thin-border-style: $thin-border-style;
	border: $border;
	radius: $radius;
	radius-small: $radius-small;

	system-var-color: $system-var-color;
	standard-var-color: $standard-var-color;
	custom-var-color: $custom-var-color;

	exp-validated-color: $exp-validated-color;
	exp-not-validated-color: $exp-not-validated-color;

	draft-color: $draft-color;
	ready-color: $ready-color;
	in-progress-color: $in-progress-color;
	completed-color: $completed-color;
	disabled-color: $disabled-color;

	notebook-reviewed-color: $notebook-reviewed-color;

	completion-partial-color: $completion-partial-color;
	completion-completed-color: $completion-completed-color;
}
