@import "src/sass/main.scss";

.ProgressBar-container {
	h1 {
		color: $tertiary-font-color;
		font-size: $font-size-small;
		font-weight: normal;
	}
}

.ProgressBar-body {
	height: 20px;
	border-radius: $radius;
	background-color: $white-transparent;

	* {
		height: 100%;
		border-radius: $radius;
	}
}

.ProgressBar-progress {
	min-width: 1%;
	max-width: 100%;
	background-color: $primary-color-light-1;
}

.ProgressBar-label {
	line-height: 20px;
	margin-top: -20px;
	padding-left: $padding-small;
}
