@import "src/sass/main.scss";

.DashboardHeader-body {
	background-color: $white;
	border-bottom: $border-style;
	padding: $padding-small;
	display: flex;
	gap: $padding-small;
	align-items: center;
}

.DashboardHeader-combobox {
	width: 250px;
}

.DashboardHeader-checkboxes {
	display: flex;
	flex-direction: column;
	padding-left: $padding;
	padding-right: $padding-large;
}

.DashboardHeader-checkboxes-container {
	display: flex;
	width: fit-content;
	gap: $padding-small;
}

.DashboardHeader-checkboxes-title {
	width: 100%;
	color: $tertiary-font-color;
	font-size: 0.7em;
	margin-bottom: -8px;
	padding-left: $padding-small;
}
