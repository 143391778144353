@import "src/sass/main.scss";

.DashboardVarietyPage-body {
	padding: $padding-small;
	overflow-y: auto;
	gap: $padding;
}
.DashboardVarietyPage-double-charts {
	display: flex;
	gap: $padding;
}
.DashboardVarietyPage-chart-exp-status {
	max-width: 400px;
	min-height: 400px;
}

.DashboardVarietyPage-chart-material-grid {
	min-height: 400px;
}
