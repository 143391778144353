@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/inter400.woff2") format("woff2");
}
@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/inter500.woff2") format("woff2");
}
@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	src: url("../fonts/inter600.woff2") format("woff2");
}

body {
	margin: 0;
}
