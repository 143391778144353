@import "src/sass/main.scss";

.CreateVariableGroupModal-container {
	height: 100%;
}

.CreateVariableGroupModal-container-button {
	border-top: $border-style;
	margin-top: auto;
	gap: $padding;
	padding: $padding;
}
