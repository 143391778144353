@import "src/sass/main.scss";

.DashboardPlanningPage-container {
	background-color: $white;
	display: flex;
}

.DashboardPlanningPage-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.DashboardPlanningPage-combobox {
	flex: 1;
}
.DashboardPlanningPage-header {
	background-color: $white;
	border-bottom: $border-style;
	padding: $padding-small;
	display: flex;
	gap: $padding-small;
	align-items: center;
}
