@import "src/sass/main.scss";
@import "stories/constants/Colors/Colors.scss";

.TemplateObsEditionPage-container {
	display: flex;
	position: relative;
}

.TemplateObsEditionPage-edit-part {
	background-color: $white;
	height: 100%;
}

.TemplateObsPart-container {
	min-width: 500px;
	height: 100%;
	background-color: $neutral20;
}

.TemplateObsPart-body {
	gap: $padding;
	overflow-y: auto;
}

.TemplateObsEditionPageTabContainer {
	background-color: $neutral20;
}
