@import "src/sass/main.scss";

.UserBanner-container {
	display: flex;
	flex-direction: row;
	column-gap: $padding;
	row-gap: $padding-small;
	justify-content: space-between;
	padding: $padding-small;
	width: 100%;

	* {
		transition: none;
	}
}

.UserBanner-icon p {
	font-size: large;
	font-weight: bold;
}

.UserBanner-left {
	overflow: hidden;
	display: flex;
	gap: $padding-small;
	align-items: center;
}

.UserBanner-name {
	overflow: hidden;
}

.UserBanner-name p {
	color: $black;
	font-weight: bold;

	overflow: hidden;
	white-space: nowrap;
	/* Don't forget this one */
	text-overflow: ellipsis;
}