@import "src/sass/main.scss";

.ECWHeader-container {
	background-color: $secondary-background-color;
}
.ECWHeader-steps {
	padding: $padding;
	width: 500px;
}
.ECWHeader-title {
	align-items: center;
	display: flex;
	padding: 0 $padding;
}

.ECWHeader-title-status {
	margin-left: auto;
}
