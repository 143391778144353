@import "stories/constants/Size/Size.scss";
.HeaderTitleSearchButton {
	display: flex;
	justify-content: space-between;
}
.HeaderTitleSearchButton-create {
	flex-direction: column;
	align-items: flex-start;
	gap: $sm;
}
.HeaderTitleSearchButton-container {
	max-width: 100%;
}
.HeaderTitleSearchButton-search {
	margin-top: $sm;
}
.HeaderTitleSearchButton-button {
	margin-bottom: $x3s;
}
@media (max-width: 900px) {
	.HeaderTitleSearchButton {
		flex-direction: column;
		align-items: flex-start;
	}
	.HeaderTitleSearchButton-container,
	.HeaderTitleSearchButton-search,
	.HeaderTitleSearchButton-search > .InputTextCustom,
	.HeaderTitleSearchButton-button > .MuiButton-root {
		width: 100%;
	}
	.HeaderTitleSearchButton-button {
		margin-top: $base;
		width: 100%;
	}
}
