@import "src/sass/main.scss";

.ObsRoundModalSendNotebooks {
	gap: $padding-large;
	padding: $padding-small;
}

.ObsRoundModalSendNotebooks-footer {
	display: flex;
	justify-content: flex-end;
}

.ObsRoundModalSendNotebooks-list > li {
	list-style-position: inside;
}
