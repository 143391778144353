@import "src/sass/main.scss";
@import "stories/constants/Colors/Colors.scss";
@import "stories/constants/Size/Size.scss";

.DorianeDataGrid {
	overflow-x: auto;
}
.DorianeDataGrid.DorianeDataGridSecondaryStyle {
	overflow-x: overlay;
}
.DorianeDataGrid.DorianeDataGrid {
	width: 100%;
}

.DorianeDataGridRow,
.DorianeDataGridRowSecondaryStyle,
.DorianeDataGridRowHeader {
	display: grid;
	transition: none;
	width: fit-content;
	min-width: 100%;
}

.DorianeDataGridRowSecondaryStyle.DorianeDataGridRow {
	background-color: $neutral10;
	border-left: $borderStroke solid $neutral30;
	border-right: $borderStroke solid $neutral30;
	border-bottom: $borderStroke solid $neutral30;
	padding: $base;
	height: fit-content;
}

.DorianeDataGrid-data {
	overflow-y: auto;
	width: fit-content;
	min-width: 100%;
}
.DorianeDataGrid.DorianeDataGridSecondaryStyle > .DorianeDataGrid-data {
	height: 100%;
}

.DorianeDataGrid-data,
.DorianeDataGridRowHeader {
	overflow-y: scroll;
	overflow-x: hidden;
}
.DorianeDataGridRow {
	line-height: 35px;
	grid-template-rows: var(--row-height);
	height: var(--row-height);
	line-height: var(--row-height);
	overflow: hidden;
}

.DorianeDataGridRow {
	cursor: pointer;
	background-color: $white;
	min-height: $x3l;
}

.DorianeDataGridRow:nth-child(even) {
	background-color: $neutral20;
}
.DorianeDataGridRowSecondaryStyle.DorianeDataGridRow:nth-child(even) {
	background-color: $neutral10;
}

.DorianeDataGrid .DorianeDataGridRow:hover {
	background-color: $secondary-color-light;
}

.DorianeDataGridRowHeader {
	display: grid;
	background-color: $neutral30;

	> .DorianeDataGridCell {
		padding: $sm $base;
	}
}

.DorianeDataGridRowHeader.DorianeDataGridRowHeaderSecondaryStyle {
	border-top: $borderStroke solid $neutral30;
	border-left: $borderStroke solid $neutral30;
	border-right: $borderStroke solid $neutral30;
	border-top-left-radius: $cornerRadius;
	border-top-right-radius: $cornerRadius;
	padding: calc($sm / 2) $base;
	background-color: $neutral30;

	& .MuiTypography-root {
		font-size: $sm;
		font-weight: 400;
		color: $neutral90;
	}
}

.DorianeDataGridCell {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	border: $neutral40 1px solid;
	min-height: $x3l;
}

.DorianeDataGridRowHeader > .DorianeDataGridCell.DorianeDataGrid-color,
.DorianeDataGridRow > .DorianeDataGridCell.DorianeDataGrid-color {
	padding: 0;
	border-right: none;
}
.DorianeDataGridRow > .DorianeDataGridCell.DorianeDataGrid-color {
	border-top: none;
	border-bottom: none;
	border-left: none;
	width: $sm_small;
}
.DorianeDataGridRowHeader > .DorianeDataGridCell.DorianeDataGrid-color + .DorianeDataGridCell,
.DorianeDataGridRow > .DorianeDataGridCell.DorianeDataGrid-color + .DorianeDataGridCell {
	border-left: none;
	padding-left: $xs;
}

.DorianeDataGridRowHeader
	> .DorianeDataGridCell.DorianeDataGrid-color
	+ .DorianeDataGridCell:has(> span.MuiCheckbox-root),
.DorianeDataGridRow
	> .DorianeDataGridCell.DorianeDataGrid-color
	+ .DorianeDataGridCell:has(> .CheckBoxColumn-cell > span.MuiCheckbox-root),
.DorianeDataGridRowHeader > .DorianeDataGridCell:has(> span.MuiCheckbox-root) {
	padding-left: 0;
	padding-right: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.DorianeDataGridCell-padding-horizontal {
	padding: 0 $padding-small;
}

.DorianeDataGridRow,
.DorianeDataGridRowHeader {
	& > .DorianeDataGridCell:nth-child(n + 2) {
		border-left: $borderStroke #ccc solid;
	}
}

.DorianeDataGridCell-header {
	font-weight: 600;
}

.DorianeDataGrid .DorianeDataGridRow-check {
	background-color: $secondary-color-light;
}

.DorianeDataGridCellTextContainer {
	height: 100%;
	display: flex;
	align-items: center;
	padding: $xs;
}
