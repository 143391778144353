@import "src/sass/main.scss";

.DotColorPicker-container {
	width: $element-min-height;
	height: $element-min-height;
	min-width: $element-min-height;
	position: relative;
}

.DotColorPicker-container :has(.DotColorPicker-popup) {
	border: 2px $secondary-color solid;
}

.DotColorPicker-dot-container {
	border: $border $grey;
	border-radius: $radius;
	cursor: pointer;
}

.DotColorPicker-dot {
	width: 20px;
	height: 20px;
	border-radius: 50%; // Circle
}

.DotColorPicker-popup {
	position: absolute;
	z-index: 2;
	translate: 20% 0%;
	top: 0;

	* {
		transition: none;
	}
}

.DotColorPicker-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}