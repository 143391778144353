@import "src/sass/main.scss";

.SkeletonCustomCard {
	background-color: $grey-transparent;
	padding: $padding;
	border-radius: $radius;
}
.SkeletonCustomCard-flex {
	flex: 1;
}
