@import "src/sass/main.scss";

.ChartSmallWidth {
  grid-column: span 3;
}
.ChartMediumWidth {
  grid-column: span 6;
}
.ChartLargeWidth {
  grid-column: span 12;
}

.DashboardOrganizationPage-body {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(10, 130px);
  gap: $padding;

  padding: $padding-small;
  overflow-y: auto;
  gap: $padding;
}
