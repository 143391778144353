@import "src/sass/main.scss";

.ExpVarietyListPage-container {
	background-color: $primary-background-color;
}

.ExpVarietyListPage-body {
	padding: $padding;
	gap: $padding-large;
	overflow: auto;
}

.ExpVarietyListPage-list-header {
	display: flex;
	justify-content: space-between;
}

.ExpVarietyListPage-variables {
	gap: $padding-large;
}
