@import "src/sass/main.scss";

// Chip combobox
.ChipCombobox-chipList {
	display: flex;
	gap: $padding-small;
	flex-wrap: wrap;

	&.NoWrap {
		flex-wrap: nowrap;
		overflow: hidden;
	}
}

.ChipCombobox-checkbox-label {
	padding-left: $padding-small;
}

.MuiButtonBase-root.ChipCombobox-group {
	width: 100%;
	justify-content: start;
	font-weight: bold;
	&:hover {
		background-color: $grey-transparent !important;
	}
}
