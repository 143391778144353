.METTrialsGrowingAreaModal-grid-container .k-grid td {
    max-height: 1px !important;
    max-width: 1px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.METTrialsGrowingAreaModal-grid-container .k-grid td.BaseCell {
    position: relative;

    // padding: 0 !important;
    &.k-grid-content-sticky {
        position: sticky;
    }
}