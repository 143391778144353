@import "src/sass/main.scss";

.TemplateExperimentStructurePage-container {
	padding: $padding-small;
	display: flex;
	width: 50%;
}

.TemplateExperimentStructurePage-card-list {
	display: flex;
	flex-wrap: wrap;
	gap: $padding-large;
	overflow-y: auto;
	padding-top: $padding-small;
}
