@import "src/sass/main.scss";

.ColorTag-title {
	color: $tertiary-font-color;
	font-size: $font-size-small;
	font-weight: normal;
}

.ColorTag-children {
	height: 20px;
	display: flex;
	flex-wrap: nowrap;
	gap: $padding-micro;
}

.ColorTag-tag {
	padding: $padding-micro;
	min-width: $element-min-height;
	text-align: center;
	background-color: $white-transparent;
	width: fit-content;

	h3 {
		font-weight: normal;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
