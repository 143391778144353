@import "src/sass/main.scss";

.InputText > .MuiInputBase-root {
	background-color: $grey-transparent;
	border-color: $grey-dark;
}

.InputText > .MuiInputBase-root fieldset {
	border: $border $grey;
	border-radius: $radius;
}

.InputText > .MuiInputBase-root:hover fieldset {
	border-color: $secondary-color !important;
}

.InputText.MuiTextField-root label {
	line-height: 16px !important;
}

.InputText.MuiTextField-root input {
	height: $element-min-height !important;
	max-height: $element-min-height !important;
	padding: 0 $padding-small !important;
	color: $black !important;
}

.InputText > .MuiFormHelperText-root.Mui-error {
	margin: 0;
	line-height: 100%;
	padding: $padding-micro 0 $padding-micro $padding-micro;
}
