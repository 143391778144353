@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";
@import "stories/constants/Colors/Colors.scss";

.SettingPage-page {
	flex: 1;
	height: 100%;
}

.SettingPage-tabs {
	background-color: $primary-background-color;
}

.SettingPage-selected-page {
	flex: 1;
	overflow: auto;
	background-color: $neutral20;
}

.SettingPage-page-tab {
	padding: 0 $xl;
}
