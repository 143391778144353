@import "src/sass/main.scss";

.ExpGeneralForm-title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ExpGeneralForm-market-segment-list {
	gap: $padding-small;
}
