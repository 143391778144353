@import "../../constants/Size/Size.scss";

.tooltipDoriane > .MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
	&.MuiTooltip-tooltipPlacementTop {
		margin-bottom: $sm_small;
	}
	&.MuiTooltip-tooltipPlacementBottom {
		margin-top: $sm_small;
	}
	&.MuiTooltip-tooltipPlacementLeft {
		margin-right: $sm_small;
	}
	&.MuiTooltip-tooltipPlacementRight {
		margin-left: $sm_small;
	}
}
