@import "src/sass/main.scss";

// Kendo styles
// https://www.telerik.com/kendo-react-ui/components/styling/theme-default/customization-color-system/
//$kendo-color-primary: #99cc33;
//@import "~@progress/kendo-theme-default/dist/all.scss";
//@import "~@progress/kendo-theme-default/scss/grid/_index.scss";

* {
	font-family: "DidactGothic-Regular";
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color: transparent;
	color: var(--black);
}

// break the datagrid if selector is only *
/*
#root * {
	transition: all 0.3s ease;
}*/

@font-face {
	font-family: "DidactGothic-Regular";
	src: local("DidactGothic-Regular"),
		url("./assets/fonts/DidactGothic-Regular.ttf") format("truetype");
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	border-radius: 5px;
	background-color: $grey;
}

::-webkit-scrollbar-track {
	-webkit-border-radius: 5px;
	border-radius: 5px;
	cursor: pointer;
}

/* Handle */

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 5px;
	border-radius: 5px;
	background: $grey-dark;
	cursor: pointer;
}

::-webkit-scrollbar-thumb:window-inactive {
	background: $grey-dark;
}

h1,
h2,
h3,
h4 {
	padding: 0;
	color: $black;
}

h1 {
	font-size: $font-size-large;
}

h2 {
	font-size: $font-size;
}

h3 {
	font-size: $font-size;
	margin-top: 2px;
}

body {
	display: block;
	height: 100vh;
	width: 100vw;
	padding: 0;
	margin: 0;
}

.full-parent-size {
	width: 100%;
	height: 100%;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.take-remaining-space {
	flex: 1;
	min-height: 0;
}

.overflow-auto {
	overflow: auto;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-center-full-height {
	display: flex;
	align-items: center;
	height: 100%;
}

.clamped-text {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 1;
	word-break: break-all;
	line-height: normal;
}

.text-warn {
	color: $warn-font-color;
}

.text-error {
	color: $err-font-color;
}

.text-success {
	color: $success-font-color;
}

.text-info {
	color: $info-font-color;
}

.no-animation,
.no-animation *,
#root .no-animation * {
	transition: none;
}
