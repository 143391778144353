@import "src/sass/main.scss";

.DorianeModal-container {
	z-index: 50;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $padding-large;
}

.DorianeModal-background {
	z-index: 50;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	background-color: $black-transparent;
}



.DorianeModal-background-clickable {
	cursor: pointer;
}

.DorianeModal {
	min-width: 300px;
	min-height: 150px;
	background-color: $white;
	border-radius: $radius;
	padding: $padding-small;
	border: $grey $border;
	z-index: 51;

	.ModalTitle-title {
		padding: 0;
		padding-left: $padding-small;
	}
}

// preset-CSS for children
.DorianeModal-child-button-list {
	display: flex;
	margin-top: auto;
	gap: $padding-small;
	justify-content: flex-end;
}

.DorianeModal-child-text-area {
	padding: $padding-small;
	gap: $padding;

	li {
		list-style-position: inside;
	}
}
