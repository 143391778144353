@import "src/sass/main.scss";

.SideMenu {
	background-color: $white-transparent;
	background: url("/assets/background_halloween.png") no-repeat;
	background-size: cover;
	background-position: center;
	background-size: cover;
	height: 100%;
	width: 200px;
	display: flex;
	flex-direction: column;
	padding: $padding-small;
}

.SideMenu-list {
	list-style-type: none;
	gap: $padding-small;
	display: flex;
	flex-direction: column;

	li {
		cursor: pointer;
		padding: $padding-small;
		border-radius: $radius;
		display: flex;
		gap: $padding-small;
		font-weight: bold;
		line-height: 25px;
	}
}

.SideMenu-list-selected-item {
	background-color: $secondary-color-transparent;
	border-left: 4px $secondary-color solid;
	transition: none;
	color: $white;
}

.SideMenu-list-item {
	color: $secondary-font-color;

	&:hover {
		background-color: rgba(255, 255, 255, 10%);
	}
}

.SideMenu-option {
	margin-top: auto;
}

.SideMenu-option-item {
	display: flex;
	gap: $padding-small;
}

.SideMenu-list-item-icon {
	height: 29px;
	width: 29px;
	filter: brightness(0) invert(1);
}
