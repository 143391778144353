@import "src/sass/main.scss";

.GrowingAreaBanner-container {
	row-gap: $padding-micro;
}

.GrowingAreaBanner-text {
	font-weight: bold;

	h1 {
		color: black;
		-webkit-line-clamp: 1;
	}

	h2 {
		color: $primary-color-dark-1;
		-webkit-line-clamp: 3;
	}
}

.GrowingAreaBanner-tags {
	display: flex;
	flex-wrap: wrap;
	color: $primary-color-dark-1;
	column-gap: $padding;
	row-gap: $padding-small;
}

.GrowingAreaBanner-top {
	display: flex;
}
.GrowingAreaBanner-delete {
	margin-left: auto;
	img {
		height: 15px;
		width: 15px;
	}
}
