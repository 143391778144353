@import "src/sass/main.scss";

.TrialEditionPage-container {
	background-color: $tertiary-color-1;
	position: relative;
}

.TrialEditionPage-elements {
	min-width: 40%;
	background-color: $white;
}

.TrialEditionPage-elements-small {
	max-width: 850px;
}

.TrialEditionPage-header {
	height: 100px;
	background-color: teal;
}

.TrialEditionPage-right-side {
	min-width: 600px;
	background-color: $primary-background-color;
}
