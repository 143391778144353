@import "src/sass/main.scss";

.LoginCarouselItem-container {
	padding: $padding-large;
}
.LoginCarouselItem-img {
	height: 150px;
	width: 300px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.LoginCarouselItem-container {
	align-items: center;
	justify-content: center;
	text-align: center;
}

.LoginCarouselItem-title {
	padding: $padding-small;
}
