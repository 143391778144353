@import "src/sass/main.scss";

.PictureBanner-container {
	row-gap: $padding-micro;
}

.PictureBanner-text {
	font-weight: bold;

	h1 {
		color: black;
		-webkit-line-clamp: 1;
	}

	h2 {
		color: $primary-color-dark-1;
		-webkit-line-clamp: 3;
	}
}

.PictureBanner-tags {
	display: flex;
	flex-wrap: wrap;
	color: $primary-color-dark-1;
	column-gap: $padding;
	row-gap: $padding-small;
}

.PictureBanner-top {
	display: flex;
	flex-direction: column;
}
