$primary10: #e7f3f1;
$primary20: #cfe7e3;
$primary30: #9ecfc7;
$primary40: #6eb7aa;
$primary50: #3d9f8e;
$primary60: #0d8772;
$primary70: #0a6c5b;
$primary80: #085144;
$primary90: #05362e;
$primary100: #042822;
$primary110: #031b17;
$accent10: #f5faeb;
$accent20: #ebf5d6;
$accent30: #d6ebad;
$accent40: #c2e085;
$accent50: #add65c;
$accent60: #99cc33;
$accent70: #8ab82e;
$accent80: #7aa329;
$accent90: #6b8f24;
$accent100: #4d661a;
$accent110: #2e3d0f;
$active10: #f4fcff;
$active20: #dbf5ff;
$active30: #b1eaff;
$active40: #95e3ff;
$active50: #33c0f3;
$active60: #00b0f0;
$active60_fade: #00b0f060;
$active70: #008dc0;
$active80: #007ba8;
$active90: #006a90;
$active100: #005878;
$active110: #004660;
$activeCustomBorder: #BFE2EF;
$yellow10: #fffae6;
$yellow20: #fff4ce;
$yellow30: #fff2c1;
$yellow40: #ffeba6;
$yellow50: #ffd15b;
$yellow60: #e8b605;
$yellow70: #e2b30b;
$yellow80: #b8880e;
$yellow90: #9a7925;
$yellow100: #7b6333;
$yellow110: #5b5745;
$red10: #fce8ec;
$red20: #f8d0d8;
$red30: #f1a1b1;
$red40: #ea728a;
$red50: #e34343;
$red60: #dc143c;
$red70: #c61236;
$red80: #b01030;
$red90: #9a0e2a;
$red100: #840c24;
$red110: #6e0a1e;
$brown10: #fffcfa;
$brown20: #f8f2ed;
$brown30: #dccbbc;
$brown40: #7b6333;
$neutral10: #ffffff;
$neutral20: #fafafa;
$neutral30: #efefef;
$neutral40: #cdd1d1;
$neutral50: #b3bbb9;
$neutral60: #a4adab;
$neutral70: #8e9997;
$neutral80: #778482;
$neutral90: #60706d;
$neutral100: #334743;
$neutral110: #1c322e;
