@import "src/sass/main.scss";

.ECWTemplateObsSummary-body {
	overflow-y: auto;
	padding: $padding;
	padding-top: 0;
	gap: $padding;
}

.ECWTemplateObsSummary-obs-template-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ECWTemplateObsSummary-linked-container {
	padding-top: $padding;
}
