@import "src/sass/main.scss";

.CustomTooltip-children:hover {
	+ .CustomTooltip-text {
		opacity: 1;
	}
}
.CustomTooltip-container {
	position: relative;
}
.CustomTooltip-text {
	&.CustomTooltip-position-above {
		bottom: 125%;
		left: 50%;
		translate: -50%;
	}

	&.CustomTooltip-position-left {
		bottom: 0%;
		left: -100%;
		translate: -80%;
	}

	&.CustomTooltip-position-under {
		top: 125%;
		left: 50%;
		translate: -50%;
	}

	padding: $padding-small;
	cursor: default;
	width: max-content;
	max-width: 180px;
	text-align: center;
	border-radius: $radius;
	background-color: $grey;
	position: absolute;
	z-index: 1;
	opacity: 0;
}
