@import "src/sass/main.scss";

.TrialLocationMap-swap-size {
  width: 40px;
  height: 40px;
}

.TrialLocationMap-swap-container {
  top: 0;
  right: 0;
  position: absolute;
  background-color: $white;
  border-radius: $radius-large;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; // same than google map
}

// class specific to google maps
.TrialLocationMap {
  & .gm-svpc,
  & .gmnoprint > div {
    // for google stree + zoom buttons
    border-radius: $radius-large !important;
  }
  // for map/satellite switch
  & .gmnoprint > .gm-style-mtc:nth-child(1) > button {
    border-radius: $radius-large 0 0 $radius-large !important;
  }
  & .gmnoprint > .gm-style-mtc:nth-child(2) > button {
    border-radius: 0 $radius-large $radius-large 0 !important;
  }
  & .gmnoprint > .gm-style-mtc > ul {
    border-radius: 0 0 $radius-large $radius-large !important;

    & > li {
      border-radius: 0 0 $radius-large $radius-large !important;
    }
  }
}
