@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";

.LimitedChoicesForm-choices-selected-container {
	gap: $sm;
	display: flex;
	padding-left: $sm_small;
	flex-wrap: wrap;
}

.LimitedChoicesForm-chip-choice-label {
	font-weight: bold;
	padding-right: $padding-micro;
}
