@import "../../constants/Size/Size.scss";

.infoMessageContainer {
	padding: $xs $base;
	border-radius: $cornerRadius;
	display: flex;
	justify-content: space-between;
}

.infoMessageInnerContainer {
	display: flex;
	align-items: center;
}
.infoMessageIconContainer {
	margin-right: $xs;
}
