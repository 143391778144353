@import "src/sass/main.scss";

.ImgBannerPictureCard-container {
	background-color: $secondary-background-color;
	border-radius: $radius;
	display: flex;
	flex-direction: column;
	word-break: break-word;
	padding: 0;
}

.ImgBannerPictureCard-body {
	padding: $padding-small;
	// padding-left: $padding; // Fix honteux pour régler une card dépassant à cause de quelques pixels...
	flex: 1;
	min-width: 0;
}

.ImgBannerPictureCard-side-banner-image {
	border-radius: $radius;
	overflow: hidden;
	border:$border-style; 
	background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
	width: 100%;
	height: 171px;
	background-repeat: "no-repeat";
	background-size: "cover";
}

.ImgBannerPictureCard-side-banner-image-side {
	display: flex;
	justify-content: right;
	margin: 5px;
}

.ImgBannerPictureCard-side-banner-image-layer {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content:flex-end;
}

.ImgBannerPictureCard-side-banner-image-icon{
	height: 20px;
	width: 20px;
}

.ImgBannerPictureCard-side-banner-image-layer-like-button{
	background-color: $white;
}