@import "src/sass/main.scss";

.ChartCard-container {
	background-color: $white;
	border-radius: $padding;
	width: 100%;
	height: 100%;
	border: $border-style;
	padding: $padding;
}

.ChartCard-header {
	display: flex;
}

.ChartCard-text {
	font-size: $font-size;
}

.ChartCard-chart {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ChartCard-option-part {
	display: flex;
	margin-left: auto;
	align-items: flex-start;
	gap: $padding-small;
}

.ChartCard-option-buttons {
	display: flex;
}

.ChartCard-option-labeled {
	align-items: center;

	& p {
		font-size: 9px;
		translate: 0 -12px;
	}
}

.ChartCard-combobox {
	width: 200px;
}
