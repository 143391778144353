@import "src/sass/main.scss";

.TrialValidationPage-container {
	gap: $padding-small;
	background-color: $grey;
}

.TrialValidationPage-variable,
.TrialValidationPage-lvl {
	width: 250px;

	&>.MuiInputBase-root {
		background-color: $white !important;
	}
}

.TrialValidationPage-body {
	gap: $padding-small;
	flex: 1;

	padding: 0 $padding $padding $padding;
}

.TrialValidationPage-kpi {
	display: flex;
	gap: $padding-large;
}

.TrialValidationPage-header-filter {
	display: flex;
	gap: $padding;
}

.TrialValidationPage-header-menu {
	display: flex;
	background-color: $white;
	padding: $padding-small $padding;
	border-bottom: $border-style;
	border-top: $border-style;
}

.TrialValidationPage-header-save {
	margin-left: auto;
}