@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";

.TemplateObservationListPage-container {
	display: flex;
}

.TemplateObservationListPage-card-list {
	display: flex;
	flex-wrap: wrap;
	gap: $padding;
	overflow-y: auto;
	padding: $padding-small $xl $xl;
}

.TemplateObservationListPage-modal-footer {
	display: flex;
	width: 100%;
	gap: $padding-small;
	justify-content: flex-end;
}

.TemplateObservationModal-form {
	padding: $padding-small;
}

.TemplateObservationListPage-list-headerContainer {
	margin: $sm $x2l $sm $xl;
}
