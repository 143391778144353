@import "src/sass/main.scss";

.MaterialDetailCharts-composed-container {
	background-color: $white;
	border-radius: $padding;
	border: $border-style;
	padding: $padding;
	width: 100%;

	& .DashboardPage-composed-text {
		font-size: $font-size;
	}

	& .DashboardPage-composed-options {
		display: flex;
		gap: $padding;
		align-items: center;
		padding: $padding-small 0;
		padding-top: $padding;
	}

	& .DashboardPage-composed-combobox {
		width: 300px;
	}

	& .DashboardPage-composed-combobox-small {
		width: 200px;
	}
}

.MaterialDetailCharts-chart-materialVariable-geo {
	min-height: 600px;
	padding-bottom: 0;
}
.MaterialDetailCharts-chart-material-variable {
	min-height: 600px;
	max-width: 400px;
}
