@import "src/sass/main.scss";

.CompletionColumn-container {
	width: 100%;
	height: 100%;
	position: relative;
}

.CompletionColumn-background {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.CompletionColumn-text-container {
	text-align: center;
	position: absolute;
	width: 100%;
}
