@import "src/sass/main.scss";

.TitledTag-title {
	color: $tertiary-font-color;
	font-size: $font-size-small;
	font-weight: normal;
}

.TitledTag-container {
	max-width: 100%;
	display: flex;
	flex-direction: column;
}

.TitledTag-children {
	height: $padding;
	display: flex;
	flex-wrap: nowrap;
	gap: $padding-micro;
}

.Tag {
	padding: $padding-micro;
	min-width: $element-min-height;
	text-align: center;
	width: fit-content;
	border-radius: $radius;
	max-width: 100%;
	background-color: $white-transparent;
	line-height: $font-size-small;

	h3 {
		font-weight: normal;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
