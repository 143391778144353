.GrowingAreaMapMarker-pointer {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 15px 30px 0 30px;
  border-color: white transparent transparent transparent;
  margin: auto;
  &.GrowingAreaMapMarker-pointer-hide {
    border-color: transparent;
  }
}
