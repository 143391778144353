@import "src/sass/main.scss";

.UserForm-container {
	padding: $padding;

	h1 {
		padding-bottom: $padding-micro;
	}
}

.UserForm-block {
	padding-bottom: $padding;
	gap: $padding;
}

.UserForm-double-input {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: $padding-small;
}