@import "src/sass/main.scss";
@import "stories/constants/Colors/Colors.scss";
@import "stories/constants/Size/Size.scss";

.CustomVariableForm-container {
	padding-bottom: $md;
	h2 {
		color: $tertiary-font-color;
		font-size: $font-size-small;
		font-weight: normal;
	}
}

.CustomVariableForm-block {
	gap: $padding;
	& > .MuiFormControlLabel-root {
		margin-left: 0;
		margin-right: $xs;
	}
	& * > .MuiFormControlLabel-root {
		margin-left: 0;
	}
}

.CustomVariableForm-double-input {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: $md;
}

.CustomVariableForm-subtitle {
	padding-bottom: $padding-medium;
}
.CustomVariableForm-block-limitedChoiceContainer {
	border-radius: $cornerRadius;
	background-color: $neutral20;
	padding: 0 $base $xs 0;
	gap: 15px;
}
