@import "../../constants/Size/Size.scss";

.chipContainer {
	min-height: $xl;
	display: inline-flex;
	align-items: center;
	padding-left: $xs;
	padding-right: $xs;
	border-radius: 5px;
}

.chip {
	display: inline-flex;
	align-items: center;
}

.chipCloseContainer {
	margin-left: $xs;
	cursor: pointer;
}

.chipUserContainer {
	margin-right: $xs;
}
