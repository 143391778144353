@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";

.VariablesOntologyPage-container {
	position: relative;
	padding: $padding-small;
	display: flex;
}

.VariablesOntologyPage-separator {
	height: $padding-small;
}

.VariablesOntologyPage-listContainer {
	margin-top: $md;
}
