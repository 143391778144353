@import "src/sass/main.scss";

.ExpGanttPage-container {
	background-color: $white;
	display: flex;
}

.ExpGanttPage-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}
