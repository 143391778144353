@import "src/sass/main.scss";

.UserTag-badge {
	height: 17px;
	width: 17px;
	padding: $padding-nano;
	color: $white;
	font-size: $font-size-small;
	font-weight: bold;
	white-space: nowrap;
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}
