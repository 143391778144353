@import "src/sass/main.scss";

.KPICard-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.KPICard-container {
	width: 120px;
	border: $border-style;
	border-radius: $radius-large;
	padding: 0 $padding-micro;
	background-color: $white;
	overflow: hidden;
}

.KPICard-value {
	text-align: center;
	font-weight: bold;
	padding-bottom: $padding-micro;
	color: $black;
}

.KPICard-title {
	font-weight: bold;
}
.KPICard-hint {
	font-weight: 400;
	color: $grey-dark;
	font-size: $font-size-small;
}
