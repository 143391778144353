@import "../../constants/Size/Size.scss";

.badgeContainer {
	padding-left: $x2s;
	padding-right: $x2s;
	display: inline-flex;
	align-items: center;
	height: $md;
	border-radius: $cornerRadius;
	box-sizing: border-box;
	width: fit-content;
	max-width: 100%;
}

.badgeIconContainer {
	margin-right: $x2s;
}
