@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
}
.MuiAutocomplete-root.dropdown_multiple
	> .MuiFormControl-root
	> .MuiInputBase-root {
	padding-left: $x2s;
}
.MuiAutocomplete-root > .MuiFormControl-root.InputTextCustom {
	width: $inputWidth;
}
.MuiAutocomplete-root.dropdown_multiple > .MuiFormControl-root.InputTextCustom {
	width: $doubleInputWidth;
}

.MuiAutocomplete-paper > .MuiAutocomplete-listbox > .MuiAutocomplete-option {
	background-color: $neutral10;

	&:first-child {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
	&:last-child {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}
}

.MuiAutocomplete-paper
	> .MuiAutocomplete-listbox
	> .MuiAutocomplete-option[aria-selected="true"],
.MuiAutocomplete-paper
	> .MuiAutocomplete-listbox
	> .MuiAutocomplete-option[aria-selected="true"].Mui-focused {
	background-color: $active40;
}

.MuiAutocomplete-paper
	> .MuiAutocomplete-listbox
	> .MuiAutocomplete-option.Mui-focused {
	background-color: $active20;
}

.MuiAutocomplete-root
	> .MuiFormControl-root
	> .MuiInputBase-root
	> .dropdown_chipContainer {
	min-height: 45px;
	display: flex;
	align-items: center;
	margin-left: $x2s;
	margin-right: $x3s;

	& > .MuiAutocomplete-tag {
		margin-left: $x2s;
	}
}

.MuiAutocomplete-endAdornment
	> .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
	padding: 0;
	margin-right: $x2s;
}

.MuiAutocomplete-popper > .MuiAutocomplete-paper {
	border: $active20 $x3s solid;
	position: relative;
	top: $x2s;
	padding: 1px;
	background-color: $active60;
	& > .MuiAutocomplete-listbox {
		padding: 0px;
		border-radius: 7px;
	}
}

.MuiAutocomplete-popper > .MuiAutocomplete-paper.MuiPaper-root {
	width: auto;
}
