@import "src/sass/main.scss";

.PictureDetailDrawer {
	gap: $padding-large;
	padding: $padding-small;
	overflow: auto;
}

.PictureDetailDrawer-top {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}


.PictureDetailDrawer-picture-preview{
	width: 100%;
	height: auto;
	border-radius: 15px;
	overflow: hidden;
	background-color: $grey;
	position: relative;
	border:$border-style; 
}


.PictureDetailDrawer-picture-preview-img{
	width: 100%;
	height: 100%;
	
}

.PictureDetailDrawer-picture-preview-img:hover{
	width: 100%;
	height: 100%;
	cursor:pointer;

}

.PictureDetailDrawer-picture-preview-img-button{
	position: absolute;
	top:$padding;
	right: $padding;
	background-color: $white;
}

.PictureDetailDrawer-image-layer {
	border-radius: $radius;
	display: flex;
	justify-content: center;
	width: 25px;
	height: 25px;
	background-color: #ffffff;

	padding: 2px $padding-micro;
	text-align: center;
}

.PictureDetailDrawer-image-icon {
	height: 20px;
	width: 20px;
}

.PictureDetailDrawer-tag{
	background-color: $secondary-background-color;
}

.PictureDetailDrawer-list{
	display: flex;
	flex-direction: column;
	gap: $padding;
	padding-bottom: $padding;
}