@import "src/sass/main.scss";

.DynamicFormExample-container {
	background-color: rgb(208, 245, 229);
	padding: 20px;
}

.DynamicFormExample-body {
	border-radius: 15px;
	padding: 20px;
	background-color: white;
	overflow-y: auto;
}

.DynamicFormExample-title {
	display: flex;
	gap: $padding;
}
