$x3s: 2px;
$x2s: 4px;
$xs: 8px;
$sm_small: 10px;
$sm: 12px;
$base: 16px;
$md: 20px;
$lg: 24px;
$xl: 32px;
$x2l: 40px;
$x3l: 48px;
$x4l: 56px;
$x5l: 64px;
$borderStrokeThin: 0.5px;
$borderStroke: 1px;
$borderStrokeDouble: 2px;
$borderStrokeBig: 5px;
$borderRadius: 5px;
$cornerRadius: 10px;
$inputWidth: 340px;
$doubleInputWidth: 700px;
