@import "src/sass/main.scss";

.ExperimentGantt-origin-img {
    max-height: 25px;
}

.k-task-summary {
    background-color: rgba(255, 221, 137, 0.3) !important;

    .k-task-summary-complete {
        background-color: rgb(255, 221, 137)
    }
}

.ExperimentGantt-row {
    .k-task-complete {
        background-color: rgb(147, 204, 70) !important;
    }

    .k-task {
        background-color: rgba(147, 204, 70, 0.5)
    }
}