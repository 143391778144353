@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.InputTextCustom {
	max-width: 100%;
}
.InputTextCustom.MuiTextField-root label {
	line-height: $base !important;
}
.InputTextCustom.MuiTextField-root * {
	z-index: 1;
}
//Conflict to see border
.textAreaCustomContainer {
	margin: 1px;
}

.InputTextCustom.MuiTextField-root label.MuiInputLabel-shrink {
	top: $x2s;
	left: -3px;
	background-color: $neutral10;
	padding: $x3s 9px $x3s 5px;
	border-radius: $xs;
}
.InputTextCustom.MuiTextField-root label {
	z-index: 2;
}

.InputTextCustom.MuiTextField-root input {
	height: 45px !important;
	padding: $sm_small $base !important;
	box-sizing: border-box;
}

.InputTextCustom.MuiTextField-root input:disabled {
	border-radius: $cornerRadius;
	-webkit-text-fill-color: unset;
}
.InputTextCustom.marginRightIcon
	> .MuiInputBase-formControl.MuiInputBase-adornedEnd {
	padding-right: $xs;
}

.fixedInput {
	width: 300px;
}

.InputTextCustom.MuiFormControl-root.MuiTextField-root
	.MuiOutlinedInput-root
	fieldset {
	z-index: 0;
	background-color: $neutral10;
}

.InputTextCustom.MuiFormControl-root.MuiTextField-root
	.MuiOutlinedInput-root.Mui-disabled
	fieldset {
	background-color: $primary20;
}
