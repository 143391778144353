@import "src/sass/main.scss";

.PicturePage-container {
	display: flex;
}

.PicturePage-filter-button {
	justify-content: flex-end;
	display: flex;
	padding: 2px $padding-micro;
}

.PicturePage-filter-button-icon {
}

.PicturePage-list-part {
	width: 670px;
	background-color: $primary-background-color;
}

.PicturePage-body {
	gap: $padding;
	padding: $padding;
}
