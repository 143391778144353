@import "src/sass/main.scss";

.DynamicForm-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.DynamicForm-input {
	padding-bottom: 16px;
}
