@import "src/sass/main.scss";

.TrialAnalysisPage-container {
	background-color: $tertiary-color-1;
}

.TrialAnalysisPage-body {
	padding: $padding-small;
	overflow-y: auto;
	gap: $padding;
}
