@import "src/sass/main.scss";

.NotebooksPage-body {
	overflow-y: auto;
	padding: $padding;
}

.NotebooksPage-card-list {
	display: flex;
	flex-wrap: wrap;
	gap: $padding-large;
}
