@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";

.ProjectPage-list {
	margin-top: $md;
}

.ProjectPage-container {
	padding: $base $xl;
}
