@import "src/sass/main.scss";

.MaterialCard {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $padding-small $padding;
}

.MaterialCard-tags {
	display: flex;
	gap: $padding-small;
}
