@import "../../../constants/Colors/Colors.scss";
@import "../../../constants/Size/Size.scss";

.sidebar-drawer-container {
	min-height: 100vh;
}

.sidebar-listElement-icon-hover {
	display: none;
}
.sidebar-listElement-icon {
	display: block;
}
#root .sidebar-drawer-inner *,
#root .sidebar-drawer-inner-bottom * {
	transition: all 0s;
}

.sidebar-listElement {
	display: flex;
	padding: $sm;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: $primary40;
		& .sidebar-listElement-icon-hover {
			display: block;
		}
		& .sidebar-listElement-icon {
			display: none;
		}
	}
}
.sidebar-listElement-selected {
	background-color: $active60_fade;
	border-left: solid $x2s $active40;
	border-right: solid $x2s transparent;
	&:hover {
		background-color: $active60_fade;
	}
}

.sidebar-drawer > .MuiPaper-root {
	display: flex;
	justify-content: space-between;
	background-color: $primary60;
	border-radius: 0;
	border: none;
	min-height: 100vh;
}

.sidebar-logo {
	cursor: pointer;
	display: flex;
	align-items: center;
}
.sidebar-logo > img {
	height: 45px;
}

.sidebar-listElement-iconContainer {
	margin-right: $sm_small;
	margin-left: $lg;
}

.sidebar-listElement-selected > .sidebar-listElement-iconContainer {
	margin-left: $md;
}

.sidebar-drawer-headerIconContainer {
	width: 0px;
	position: absolute;
}
.sidebar-drawer-headerIconInnerContainer {
	width: $lg;
	position: relative;
	left: 22px;
}
.sidebar-logo-inner {
	display: contents;
}

.sidebar-listElement .sidebar-text {
	font-size: $base;
}
@media screen and (min-width: 1600px) {
	.sidebar-listElement .sidebar-text {
		font-size: $md;
	}
}
