@import "src/sass/main.scss";

.LoginPage-container {
	height: 100%;
	min-height: 100%;
	display: flex;
	padding: $padding;
	gap: $padding-large;
	align-items: center;
	background: url("/assets/background.png") no-repeat;
}

/* LEFT SIDE */
.LoginPage-left-side {
	gap: $padding;
	height: fit-content;
	flex: 1;
	align-items: center;

	.LoginPage-rnd-exp {
		color: $secondary-font-color;
		font-size: 2em;
	}
}

/* RIGHT SIDE */
.LoginPage-right-side {
	background-color: $primary-background-color;
	height: 100%;
	border-radius: 30px;
	flex: 1;
	justify-content: center;
	padding-bottom: 100px;
	align-items: center;
}

.LoginPage-rnd-logo {
	background: url("/assets/logo.svg") no-repeat center;
	background-size: contain;
	min-height: 120px;
	min-width: 100%;
	margin-bottom: $padding;
	max-width: 80%;
}
.LoginPage-icon {
	background-image: url("/assets/pictures/login.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 400px;
	width: 400px;
	max-width: 80%;
}
.DorianeButton.LoginPage-btn {
	height: 50px;
	width: 200px;
	max-width: 80%;
}
