@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";
@import "stories/constants/Colors/Colors.scss";

.VariablesPage-container {
	background-color: $tertiary-background-color;
}
.VariablesPage-container-tabs {
	padding: 0 $xl;
}
.VariablesPage-tab-container {
	background-color: $neutral20;
	padding: $sm $xl;
}
