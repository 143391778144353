@import "src/sass/main.scss";

.DorianeDrawer-container {
	width: 450px;
	background-color: $white;
	border-left: $border-style;
}
.DorianeDrawer-absolute {
	z-index: 100;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
}

.DorianeDrawer-take-space {
	min-width: 435px;
	flex: 1;
}
