@import "src/sass/main.scss";

.MuiCheckbox-root.CustomCheckbox {
	padding: 2px;
	color: $grey-dark;

	&.Mui-checked {
		color: $secondary-color;
	}

	&.Mui-disabled {
		color: $grey-dark;
		opacity: 0.5;
	}

	& .MuiSvgIcon-root {
		border-radius: $radius;
		width: $padding-large;
		height: $padding-large;
	}

	* {
		transition: all 0.1s ease;
	}
}

.MuiFormControlLabel-root.CustomCheckbox {
	display: contents;
}
