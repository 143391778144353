@import "src/sass/main.scss";

.MarketSegmentBanner-container {
	justify-content: space-between;
}

.MarketSegmentBanner-title-species {
	display: flex;
	justify-content: space-between;
}

.MarketSegmentBanner-title {
	color: $primary-font-color-black;
}

.MarketSegmentBanner-tags {
	display: flex;
	flex-wrap: wrap;
	color: $primary-color-dark-1;
	gap: $padding-small;
	overflow-y: scroll;

	.MarketSegmentBanner-tags-right-side {
		margin-left: auto;
	}
}