@import "src/sass/main.scss";

.PictureModal {
	min-width: 100%;
	min-height: fit-content;
	max-width: 100%;
    max-height: 100%;
	padding: 0 !important;
	position: relative;
}

.PictureModal-scroll-bar{
	min-width: 100%;
	min-height: fit-content;
	max-width: 100%;
    max-height: 100%;
	overflow: auto;
	padding: 0 !important;
}

.PictureModal-picture{
	width: 100%;
	height: auto;
}

.PictureModal-img-button{
	position: absolute;
	top:$padding;
	right: $padding;
	background-color: $white;
}