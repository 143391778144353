@import "src/sass/main.scss";

.CustomCard {
	background-color: $secondary-background-color;
	border-radius: $radius;
	padding: $padding;
	cursor: pointer;

	&:hover {
		background-color: $secondary-color-light;
	}
}

.CustomCard-disabled {
	background-color: $secondary-background-color;
	border-radius: $radius;
	padding: $padding;
	cursor: default;
	border: none;
}

.CustomCard-selected {
	border: $border-selection-style;
}
