@import "src/sass/main.scss";

.IconColumn-cell {
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		max-width: 25px;
		height: 100%;
		max-height: 25px;
	}
}