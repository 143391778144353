@import "src/sass/main.scss";
@import "stories/constants/Colors/Colors.scss";
@import "stories/constants/Size/Size.scss";

.TemplatesPage-container {
	background-color: $tertiary-background-color;
}

.TemplatesPage-tab-container {
	background-color: $neutral20;
}
.TemplatesPage-container-tabs {
	padding: 0 $xl;
}
