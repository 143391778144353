@import "../../../constants/Colors/Colors.scss";
@import "../../../constants/Size/Size.scss";

.AppBarContainer {
	display: flex;
	height: $x3l;
	border-bottom: solid $borderStroke $neutral30;
	padding: $xs $xl;
	box-sizing: border-box;
	justify-content: flex-end;
	align-items: center;
	background-color: $neutral10;
}
.AppBar-initials {
	margin-left: $base;
	padding-right: $xs;
}
.AppBar-initialsContainer {
	cursor: pointer;
}
.AppBar-userPopOverContainer {
	background-color: $neutral10;
	padding: $base;
	width: max-content;
	margin-right: $base;
	border: $borderStroke solid $active60;
	border-radius: $cornerRadius;
}
