@import "src/sass/main.scss";

.Combobox > .MuiInputBase-root {
	background-color: $grey-transparent;
	border-color: $grey-dark;
}

.Combobox > .MuiInputBase-root fieldset {
	border: $border $grey;
	border-radius: $radius;
}

.Combobox > .MuiInputBase-root.Mui-focused fieldset {
	border-color: $secondary-color !important;
}

.Combobox > .MuiInputBase-root:hover fieldset {
	border-color: $secondary-color !important;
}

.Combobox.MuiFormControl-root label {
	line-height: 16px !important;
}

.Combobox .MuiSelect-multiple {
	padding: $padding-nano;
}

// position of the label
.Combobox .MuiInputLabel-outlined {
	// close
	transform: translate(12px, 7px) scale(1) !important;

	// open
	&.MuiInputLabel-shrink {
		transform: translate(12px, -8px) scale(0.75) !important;
	}
}

.Combobox .MuiOutlinedInput-input {
	max-height: $element-min-height;
}

.Combobox .MuiPaper-root {
	max-height: 400px !important;
}

.Combobox .MuiPaper-root li {
	max-height: 32px !important;
}
