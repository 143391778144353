@import "src/sass/main.scss";

.TrialLocationPage-container {
	background-color: $white;
}

.TrialLocationPage-body {
	padding: $padding;
	gap: $padding-small;
	overflow: auto;
}

.TrialLocationPage-title-container {
	display: flex;
	justify-content: space-between;
}
