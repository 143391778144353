@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";

.VarGroupList-card-list {
	gap: $md;
	overflow-y: auto;
	padding: $md 0;
}

.VarGroupList-card-container {
	max-width: $doubleInputWidth;
	margin: $xl auto;
	width: 100%;
}
