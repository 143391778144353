@import "src/sass/main.scss";

.NotFoundPage {
	width: 100%;
	min-height: 100%;
	background-color: $white-transparent;
}

.NotFoundPage-container {
	height: fit-content;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 500px;
	padding: $padding-large !important;
	background-color: $white !important;
	gap: $padding !important;
}

.NotFoundPage-img {
	width: 70%;
}