@import "src/sass/main.scss";

.ChipListCell::-webkit-scrollbar {
  height: 5px;
}

.ChipListCell {
  padding: 0 $padding-micro;
  overflow-x: auto;
  overflow-y: hidden;
}
