#root {
	background-size: cover;
	background-position: center;
	height: 100%;
}

// for glide data grid (do not remove)
#portal {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
}
