@import "src/sass/main.scss";

.CustomLinkTab.Mui-selected {
	background-color: $tertiary-background-color !important;
	color: $black !important;
	border-radius: $radius-small $radius-small 0 0;
}

.CustomSecondaryLinkTab.Mui-selected {
	color: $black !important;
}

.CustomLinkTab,
.CustomSecondaryLinkTab {
	min-height: $element-min-height !important;
	padding: 0 $padding-small !important;
}

.CustomTab {
	min-height: $element-min-height !important;
	padding: 0 $padding-small !important;
}
