@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.cardArrowContainer {
	position: relative;
}
.cardArrow {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: $sm;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: transparent;
	border-left-width: 30px;
	border-right-width: 30px;
	border-top-color: $neutral30;
	border-top-width: $md;
	transition: all 0.1s ease-in-out;
}
.cardArrowCover {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 11px;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: transparent;
	border-left-width: 30px;
	border-right-width: 30px;
	border-top-color: $neutral10;
	border-top-width: $md;
	transition: all 0.1s ease-in-out;
}

.cardContainer {
	border: 1px solid $neutral30;
	border-left-width: 5px;
	padding: $sm $base;
	border-radius: $cornerRadius;
	transition: all 0.1s ease-in-out;
	background-color: $neutral10;

	&:hover {
		border-color: $activeCustomBorder !important;
		background-color: $active10;
		& .cardArrow {
			border-top-color: $activeCustomBorder !important;
		}
		& .cardArrowCover {
			border-top-color: $active10;
		}
	}

	&.cardSelected {
		border-color: $active60 !important;
		background-color: $active10;
		& .cardArrow {
			border-top-color: $active60 !important;
		}
		& .cardArrowCover {
			border-top-color: $active10;
		}
	}
}
.cardContainer.cardBorderTop {
	border-left-width: 1px;
	border-top-width: 5px;
}

.cardHeaderContainer {
	display: flex;
	justify-content: space-between;
}
.cardHeaderLeft {
	display: flex;
	max-width: 100%;
	overflow: hidden;
}
.cardHeaderLeft-center {
	align-items: center;
}

.cardLabelContainer {
	margin-bottom: $xs;
}

.cardHeaderTitleContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-width: 0;
	gap: $x2s;
}

.cardHeaderIconContainer {
	margin-right: $xs;
}

.cardListContainer {
	display: flex;
	margin-top: $base;
}
.cardListContainer-spaceBetween {
	justify-content: space-between;
}
.cardListContainer-flexStart {
	justify-content: flex-start;
	& > .cardDataListElement {
		margin-right: $base;
	}
}
.cardDataListElementTitle {
	margin-bottom: $x2s;
}
