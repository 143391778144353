@import "src/sass/main.scss";

.ECWGermplasm-material-origin {
	min-width: 300px;
	width: 70%;
	display: flex;
	gap: $padding-small;
}

.ECWGermplasm-material-origin-type {
	flex: 1;
}
