@import "src/sass/main.scss";

.SelectVariableGroupModal-container {
    padding: $padding-small;
    gap: $padding-small;
}

.SelectVariableGroupModal-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: $padding-small;
    overflow-y: auto;
}