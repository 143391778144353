@import "stories/constants/Colors/Colors.scss";
@import "stories/constants/Size/Size.scss";

.k-grid-md .k-hierarchy-cell {
	display: none;
}
.noGridHierarchy colgroup > col:first-child {
	display: none;
}

.k-grid.k-grid-md {
	border-width: 0;
	border-top-left-radius: $cornerRadius;
	border-top-right-radius: $cornerRadius;
}
.k-grid .k-grid-header .k-table-th {
	border-width: 0;
}
div.k-grid .k-grid-header {
	border-bottom-width: 0px;
}
.k-grid .k-table-row td {
	border-inline-start-width: 0;
}

.k-grid .k-grid-header {
	background-color: $neutral20;

	& .MuiTypography-root {
		font-size: $sm;
		font-weight: 400;
		color: $neutral90;
	}
}
.k-grid.k-grid-md > .k-grid-aria-root {
	border-top-left-radius: $cornerRadius;
	border-top-right-radius: $cornerRadius;
	border: $borderStroke solid $neutral30;
}
.k-table-thead {
	border-bottom: $borderStroke solid $neutral30;
	border-bottom-width: $borderStroke;
}
.k-grid tr.k-table-row.k-table-alt-row,
.k-table-tbody tr.k-table-row:hover {
	background-color: transparent;
}
.k-grid .k-table-tbody > tr.k-table-row:not(.k-detail-row):hover,
.gridDetailsRow:hover {
	background-color: $active20;
}
.k-grid .k-table-row {
	cursor: pointer;
}
.k-grid tr.k-detail-row .k-detail-cell:nth-child(2) {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
}
.k-grid tr.k-detail-row .k-detail-cell:last-child {
	padding-right: 0;
}
