@import "src/sass/main.scss";
@import "stories/constants/Colors/Colors.scss";
@import "stories/constants/Size/Size.scss";

.MainLayoutPage-container {
	height: calc(100% - $x3l);
	display: flex;
	flex: 1;
}

.MainLayoutPage-page {
	height: 100%;
	background-color: $neutral20;
	overflow: auto;
	flex: 1;
}

.MainLayoutPage-containerRight {
	flex: 1;
	min-width: 50%;
}
