@import "../../constants/Size/Size.scss";
@import "../../constants/Colors/Colors.scss";

.radio_doriane,
.radio_doriane_label {
	& .radio_doriane-default {
		display: block;
	}
	& .radio_doriane-hover {
		display: none;
	}
}

.radio_doriane:hover,
.radio_doriane_label:hover {
	& .radio_doriane-default {
		display: none;
	}
	& .radio_doriane-hover {
		display: block;
	}
}

.radio_doriane.MuiRadio-root {
	padding: 6px;
	height: $x2l;
}

.radio_bordered {
	background-color: #ffffff;
	border-radius: $lg;
	&-end {
		padding-right: $sm_small;
	}
	&-start {
		padding-left: $sm_small;
	}
	&-top {
		padding: $sm_small $sm_small 0 $sm_small;
	}
	&-bottom {
		padding: 0 $sm_small $sm_small $sm_small;
	}
}

.MuiFormGroup-root.radio_group {
	& label {
		margin-right: $base;
		margin-left: 0px;
	}
	display: block;
}

.MuiFormGroup-root.radio_group-column {
	display: inline-flex;
	flex-direction: column;
	& label {
		margin-bottom: $x2s;
	}
}

.MuiRadio-root.Mui-disabled + .MuiTypography-root > .MuiTypography-root {
	color: $neutral70;
}
