@import "src/sass/main.scss";

.GrowingAreaForm-container {
	padding: $padding;
}

.GrowingAreaForm-title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.GrowingAreaForm-user-list {
	gap: $padding-small;
}
