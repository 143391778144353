@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.avatarIcon {
	display: inline-block;
	padding: $xs;
	border-radius: $md;
	border: $borderStrokeDouble solid $neutral10;
	width: $md;
	height: $md;
	box-sizing: content-box;
}

.avatarIconTemplateContainer {
	position: relative;
	width: 0;
	height: 0;
}
.avatarIconTemplate {
	position: relative;
	box-sizing: border-box;
	width: $md;
	height: $md;
	border-radius: $cornerRadius;
	bottom: 30px;
	left: $sm;
	border: $borderStrokeDouble solid $neutral10;
	display: flex;
	align-items: center;
	justify-content: center;
}
