@import "src/sass/main.scss";

.ECWTrialGeneration-title-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ECWTrialGeneration-title-button {
	width: 40px;
	height: 40px;
}

.ECWTrialGeneration-trial-cell {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	overflow: hidden;
	gap: $padding-micro;
}
.ECWTrialGeneration-name {
	flex-grow: 1;
}

.DorianeDataGridRow.ECWTrialGeneration-row-selected {
	background-color: $secondary-color-light;
}

.ECWTrialGeneration-table {
	flex: 1;
}

.ECWTrialGeneration-table-footer {
	padding-left: $padding;
	opacity: 0.6;
}
