@import "../../constants/Size/Size.scss";
@import "../../constants/Colors/Colors.scss";

.initials_container {
	height: $md;
	width: $md;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: $cornerRadius;
}

.initials_container.initials_container_small {
	height: $base;
	width: $base;
	border-radius: $xs;
}
.initials_container.initials_container_big {
	height: $x2l;
	width: $x2l;
	border-radius: $md;
}
.initials_container.initials_container_xxl {
	height: $x5l;
	width: $x5l;
	border-radius: $xl;
}

.initials_centered {
	position: relative;
	top: 0.5px;
	left: 0.5px;
}
.initials_centered.initials_centered_small {
	top: 0;
}

.initialsIconContainer {
	position: relative;
	width: 0;
	height: 0;
}
.initialsIcon {
	position: relative;
	box-sizing: border-box;
	width: $lg;
	height: $lg;
	border-radius: $sm;
	bottom: $x2l;
	left: $md;
	border: $borderStrokeDouble solid $neutral10;
	display: flex;
	align-items: center;
	justify-content: center;
}
