@import "src/sass/main.scss";

.TemplateExperimentStructureBanner-container {
	row-gap: $padding-micro;
}

.TemplateExperimentStructureBanner-title {
	color: black;
	font-weight: bold;
	-webkit-line-clamp: 2;
}

.TemplateExperimentStructureBanner-description {
	color: $primary-color-dark-1;
	-webkit-line-clamp: 2;
}

.TemplateExperimentStructureBanner-tags {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: $primary-color-dark-1;
	column-gap: $padding;
	row-gap: $padding-small;
}