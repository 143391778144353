@import "src/sass/main.scss";

.DashboardTeamManagers-body {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(6, 130px);
  gap: $padding;

  padding: $padding-small;
  overflow-y: auto;
  gap: $padding;
}
