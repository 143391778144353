@import "src/sass/main.scss";

.GermplasmDataPage-page {
	background-color: $secondary-background-color;
}

.GermplasmDataPage-selected-page {
	background-color: $primary-background-color;
	flex: 1;
	overflow: auto;
}
