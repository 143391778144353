@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";

.ModalTitle-title {
	color: $black;
	padding-left: $padding;
	font-size: $font-size-extra-large;
}

.ModalTitle-container {
	display: flex;
	align-items: center;
	padding: $xs $md 0;
}

.ModalTitle-close-button {
	margin-left: auto;
}

.ModalTitle-color {
	width: $padding-micro;
	height: 100%;
}
