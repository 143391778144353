@import "src/sass/main.scss";

.DorianeButton {
	padding: 0 $padding-small;
	font-weight: bold;
	border: $border;
	border-radius: $radius;
	min-height: $element-min-height;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	opacity: 0.9;
}

.DorianeButton:active {
	opacity: 1;
	box-shadow: $shadow;
	transition: 0s;
}

.DorianeButton:hover {
	opacity: 1;
}

.DorianeButton-primary {
	font-weight: bold;
	color: $secondary-font-color;
	background-color: $primary-color-light-1;
	width: fit-content;
	border: none;
}

.DorianeButton-secondary {
	color: $primary-color-dark-1;
	max-height: $element-min-height;
	width: fit-content;
	text-transform: none;
	border-color: $primary-color-dark-1;
}

.DorianeButton-tertiary {
	color: $primary-color-dark-1;
	text-transform: none;
	max-height: $element-min-height;
	width: fit-content;
	border-width: 0;
}

.DorianeButton-quaternary {
	border: none;
	height: $element-min-height;
	width: $element-min-height;
	color: $black;
}

.DorianeButton-disable {
	opacity: 0.3 !important;
	cursor: default;
}

.DorianeButton-disable:hover {
	opacity: 0.3 !important;
	box-shadow: none !important;
}

.DorianeButton-active{
	border : $border-selection-style;
}