@import "src/sass/main.scss";

.ObservationRoundBanner-container {
	row-gap: $padding-micro;
}

.ObservationRoundBanner-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ObservationRoundBanner-title {
	color: $primary-font-color-black;
	-webkit-line-clamp: 1;
}

.ObservationRoundBanner-tags {
	display: flex;
	flex-wrap: wrap;
	color: $primary-color-dark-1;
	column-gap: $padding;
	row-gap: $padding-small;
}

.ObservationRoundBanner-description {
	color: $black;
	font-weight: bold;
	-webkit-line-clamp: 3;
}
