.ObsRoundEditionGeneralPage-body {
	overflow-y: auto;
}

.ObsRoundEditionGeneralPage-stickyBar {
	position: sticky;
	top: 0;
	background: #ffffff;
	z-index: 2;
}

.ObsRoundEditionGeneralPage-form {
	width: 80%;
}
