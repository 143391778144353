@import "src/sass/main.scss";

.GrowingAreaEditPage-container {
	display: flex;
	position: relative;
}

.GrowingAreaEditPage-edit-part {
	width: 900px;
	height: 100%;
	background-color: $primary-background-color;
}
.GrowingAreaEditPage-body {
	overflow-y: auto;
}

.GrowingAreaEditPage-form {
	width: 60%;
}

.GrowingAreaEditPage-header-banner {
	background-color: $secondary-background-color;
}
