@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.MuiList-root.MuiMenu-list {
	padding: 1px;
	background-color: $active60;
	border: $x3s solid $active20;
	border-radius: 13px;
}
.MuiButtonBase-root.MuiMenuItem-root {
	background-color: $neutral10;
	height: 45px;
	&:first-child {
		border-top-left-radius: $cornerRadius;
		border-top-right-radius: $cornerRadius;
	}
	&:last-child {
		border-bottom-left-radius: $cornerRadius;
		border-bottom-right-radius: $cornerRadius;
	}
	&:hover {
		background-color: $active20;
	}
	&.dropdownOptionDisabled {
		opacity: 1;
		color: $neutral70;
		cursor: not-allowed;
	}
}

.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
	border-radius: 13px;
}
