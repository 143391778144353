@import "src/sass/main.scss";

.ExpObsUnitPage-container {
	background-color: $primary-background-color;
	padding: $padding;
	display: flex;
	gap: $padding-large;
	overflow: auto;
}

.ExpObsUnitPage-block {
	gap: $padding;
}
