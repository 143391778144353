@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.k-calendar,
.k-animation-container {
	font-family: Inter;
}

$calendarRadius: $base;
.k-calendar .k-calendar-td.k-selected .k-link,
.k-calendar .k-calendar-td.k-selected:hover .k-link {
	background-color: $active60;
	border-radius: $calendarRadius;
}
.k-calendar .k-range-mid,
.k-calendar .k-range-start,
.k-calendar .k-range-end {
	background-color: $active20;
}
.k-calendar .k-range-start,
tr.k-calendar-tr td.k-calendar-td:first-child {
	border-top-left-radius: $calendarRadius;
	border-bottom-left-radius: $calendarRadius;
}
.k-calendar .k-range-end,
tr.k-calendar-tr td.k-calendar-td:last-child {
	border-top-right-radius: $calendarRadius;
	border-bottom-right-radius: $calendarRadius;
}
.k-calendar-header .k-calendar-title {
	font-weight: 500;
}
.k-calendar .k-calendar-td {
	margin: $x2s;
	font-size: $sm;
}
.k-calendar .k-calendar-td:hover .k-link {
	background-color: $active20;
	border-radius: $calendarRadius;
}
.k-calendar .k-range-split-end::after,
.k-calendar .k-range-split-start::after {
	background-image: none;
}
.k-calendar .k-calendar-view .k-today {
	color: $red50;
}
.k-calendar .k-link {
	box-shadow: none !important;
	width: $xl;
	height: $xl;
	padding: $x2s;
}
.k-widget,
.k-widget .k-calendar {
	border-radius: $xs;
}
.k-calendar-table {
	border-spacing: 0 $x2s;
}
.k-widget {
	padding: $base $lg;
}

.k-calendar-range .k-calendar-view .k-calendar-table:last-child {
	margin-left: $lg;
}
