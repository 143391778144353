@import "../../../constants/Colors/Colors.scss";
@import "../../../constants/Size/Size.scss";

.TabsHeaderContainer {
	display: flex;
}
.MuiButtonBase-root.MuiTab-root {
	text-transform: unset;
}
.MuiButtonBase-root.MuiTab-root {
	color: $neutral100;
	font-size: $base;
	font-weight: 500;
	padding: $xs;
	min-width: unset;
	margin-right: $base;
}
.MuiButtonBase-root.MuiTab-root.Mui-selected {
	color: $active60;
}
.MuiTabs-scroller > .MuiTabs-indicator {
	background-color: $active60;
	height: $x2s;
}
.TabsContainer,
.TabsContainer * {
	max-height: $x2l;
}
.TabsContainer .MuiButtonBase-root.MuiTab-root {
	padding-top: 0;
	padding-bottom: 0;
	min-height: $x2l;
}
