@import "../../constants/Size/Size.scss";

.foldingChipsContainer {
	padding: $x2s $md;
	border-radius: $md;
	cursor: "pointer";
	justify-content: center;
	align-items: center;
	gap: $x2s;
	display: flex;
}
