@import "src/sass/main.scss";

.LoginCarousel-container {
	background-color: $primary-background-color;
	border-radius: 20px;
	flex: 4;
	justify-content: center;
	align-items: center;
	height: 500px;
	width: 400px;
	padding: 20px 0 50px 0;

	.LoginCarousel {
		max-width: 100%;
		height: 100%;

		// for lib
		.slick-dots > .slick-active button::before {
			color: $primary-color-dark-1 !important;
		}
		.slick-dots button::before {
			font-size: 20px;
		}
		.slick-list,
		.slick-track,
		.slick-slide > div {
			height: 100%;
		}
	}
}

.welcome-agro {
	background-image: url("/assets/pictures/welcome_agro.svg");
}

