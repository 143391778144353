@import "src/sass/main.scss";
@import "stories/constants/Size/Size.scss";
@import "stories/constants/Colors/Colors.scss";

.ExperimentsPage-container {
	min-width: $doubleInputWidth;
	width: 40%;
	background-color: $primary-background-color;
}
.ExperimentsPage-header {
	padding: $md $xl 0;
	background-color: $neutral20;
}
