@import "../../constants/Colors/Colors.scss";
@import "../../constants/Size/Size.scss";

.modalTitleContainer.MuiDialogTitle-root {
	border-bottom: $borderStroke solid $neutral30;
	margin-bottom: $base;
	padding: 0;
	margin: $base $lg;
	display: flex;
	flex-direction: column;
}
.modalTitleContainerTop {
	margin-bottom: $base;
}
.modalTitleContainer.MuiDialogTitle-root > .modalTitleContainerTop {
	display: flex;
	justify-content: space-between;
}
.modalTitleInnerContainer {
	display: flex;
	align-items: center;
}
.modalActionContainer.MuiDialogActions-root {
	display: flex;
	justify-content: space-between;
	margin: 0 $lg;
	padding: $base 0;
	border-top: $borderStroke solid $neutral30;
}
.modalActionButtonsContainer {
	display: flex;
}
.modalActionSecondaryContainer {
	margin: 0 $base;
}
