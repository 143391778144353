@import "src/sass/main.scss";

.TrialAnalysisHeader-body {
	background-color: $white;
	border-bottom: $border-style;
	padding: $padding-small;
	display: flex;
	gap: $padding-small;
	align-items: center;
}

.TrialAnalysisHeader-combobox {
	width: 200px;
}

.TrialAnalysisHeader-checkboxes {
	display: flex;
	flex-direction: column;
	padding-left: $padding;
	padding-right: $padding-large;
}

.TrialAnalysisHeader-checkboxes-container {
	display: flex;
	width: fit-content;
	gap: $padding-small;
}

.TrialAnalysisHeader-valid-title {
	width: 100%;
	color: $tertiary-font-color;
	font-size: 0.7em;
	margin-bottom: -8px;
	padding-left: $padding-small;
}