@import "src/sass/main.scss";

.ExpEditionGeneralPage-container {
	background-color: $primary-background-color;
}

.ExpEditionGeneralPage-body {
	padding: $padding;
	gap: $padding-large;
	overflow: auto;
}

.ExpEditionGeneralPage-form {
	width: 70%;
	gap: $padding;
}
